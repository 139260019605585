import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Input,
  notification,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";

const { Title } = Typography;
const { TextArea } = Input;
const TaskVoiceAgent = (props) => {
  const [fileList, setFileList] = useState([]);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(true);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const organization = props.organization;

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setEnabledSubmit(fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(true);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          demographics: {
            first_name: values["firstName"],
            last_name: values["lastName"],
            DOB: values["dob"],
          },
          contact: {
            email: values["email"],
            residential_address: {
              full_address: values["address"],
            },
          },
          insurance: {
            insurance_carrier: values["insurance"],
            insurance_id: values["insuranceId"],
            group_id: values["groupNumber"],
            phone: values["payerPhone"],
          },
          clinical_details: {
            procedure_codes: values["cptCodes"],
            NPI: values["npi"],
            facility: values["facility"],
            provider_address: values["providerAddress"],
            ordering_provider: {
              ordering_provider_name: values["doctor_name"],
            },
          },
          task_custom_fields: {
            expert_number: values["expertNumber"],
            objectives: values["objectives"],
          },
        },
      },
    ];

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "voice_agent",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  const isS3 = organization === "S3";
  const S3Style = isS3 ? {} : { display: "none" };
  const hiddenStyle = isS3 ? { display: "none" } : {};
  return (
    <div>
      <Title className="gpt-form-title">AI Caller</Title>
      <p>Verify patient benefits by phone referencing their medical records.</p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        loading={waiting}
        layout={"vertical"}
        initialValues={{
          objectives: isS3
            ? `{
      "supplies": {
        "mask_name": "Airfit F30i",
        "mask_size": "medium",
        "mask_position": "under the nose",
        "tubing": "ClimateLineAir",
        "machine": "Resmed-S11",
        "nasal_cushion_size": "medium",
        "nasal_pillow_size": "medium",
        "nasal_seal_size": "medium",
        "supply_period": "90 days",
        "seal_quantity": "6",
        "pillow_quantity": "6",
        "tubing_quantity": "1",
        "cushion_quantity": "6",
        "filter_quantity": "6"
      },
      "eligibility": {
        "doctor_name": "Doogie Howser",
        "insurance": "Blue Cross Blue Shield",
        "usage_reqd": true,
        "last_doctor_visit_reqd": true,
        "dysfunctions_reqd": true,
        "copay": "$25"
      },
      "contact": {
        "address": "123 Main St, Anytown, USA",
        "email": "troubletroubletrouble@yahoo.com"
      }
    }`
            : `Task objectives:
    1. Obtain information regarding the patients' benefits coverage for the required services, clearly outlining patient responsibility, plan payment, deductible status and other relevant factors.
    2. Obtain a reference number for the call.`,
          firstName: "",
          lastName: "",
          dob: "",
          doctor_name: isS3 ? "Doogie Howser" : "",
          insurance: "Blue Cross Blue Shield",
          payerPhone: "",
          expertNumber: "+919004230506",
          insuranceId: "B1223314",
          groupNumber: "92341",
          cptCodes: "95810 8/1/2023",
          npi: "",
          facility: "",
          providerAddress: "",
          address: isS3 ? "123 Main St, Anytown, USA" : "",
          email: isS3 ? "myname@example.com" : "",
        }}
      >
        {organization && organization !== "S3" && (
          <Upload
            beforeUpload={(file) => {
              const isSupportedFormat =
                [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                  "image/tiff",
                ].includes(file.type) ||
                ["pdf", "jpg", "jpeg", "png", "tiff", "tif"].includes(
                  file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase()
                );

              if (!isSupportedFormat) {
                notification.error({
                  message:
                    "Only PDF, JPG/JPEG, PNG, and TIFF files are accepted",
                });
              }
              return isSupportedFormat;
            }}
            multiple={true}
            onChange={(info) => {
              handleUpload(info);
            }}
            style={hiddenStyle}
            fileList={fileList}
            itemRender={(originNode) => {
              // Remove any tooltips
              return React.cloneElement(originNode, { title: "" });
            }}
          >
            <Button icon={<UploadOutlined />} disabled={!enabledSubmit}>
              Upload File
            </Button>
          </Upload>
        )}
        <Divider> Or Fill Details manually</Divider>
        <Form.Item
          name="objectives"
          label="Agent Objectives"
          rules={[{ required: true }]}
          style={hiddenStyle}
        >
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="dob"
          label="Date of Birth"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="payerPhone"
          label="Phone Number"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: false }]}
          style={S3Style}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: false }]}
          style={S3Style}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="doctor_name"
          label="Doctor Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="npi"
          label="NPI"
          rules={[{ required: false }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="facility"
          label="Facility Name"
          rules={[{ required: false }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="providerAddress"
          label="Ordering Provider Address"
          rules={[{ required: false }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="insurance"
          label="Insurance"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="expertNumber"
          label="Expert Phone Number"
          rules={[{ required: true }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="insuranceId"
          label="Patient’s Insurance ID"
          rules={[{ required: true }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="groupNumber"
          label="Insurance Group Number"
          rules={[{ required: true }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cptCodes"
          label="CPT codes and dates of service"
          rules={[{ required: true }]}
          style={hiddenStyle}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Place Call
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskVoiceAgent;
