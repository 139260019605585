import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UnAuthenticatedApi } from "../utils/MyApi";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { useAuthRole } from "../context/AuthRoleContext";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

const AuthCallback = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { setTriggerRefresh: triggerAuthRefresh } = useAuthRole();
  const navigate = useNavigate();

  useEffect(() => {
    const storeUIDParams = async () => {
      if (isLoading || !isAuthenticated) return;

      const auth0Token = await getAccessTokenSilently();
      const tempToken = localStorage.getItem("tempToken");
      if (!tempToken) {
        AuthenticatedApi.get("/api/revert_org")
          .then((response) => {
            console.log("Organization reverted:", response);
            triggerAuthRefresh(true);
            navigate("/tasks-list");
            return;
          })
          .catch((error) => {
            console.error("Failed to revert organization:", error);
            navigate("/tasks-list");
            return;
          });
      } else {
        try {
          await UnAuthenticatedApi.post(
            "/api/store_uid_provider",
            {},
            {
              headers: {
                Authorization: `Bearer ${tempToken}`,
                token: auth0Token,
                UID: "temp",
              },
            }
          );
          localStorage.removeItem("tempToken");
          console.log("Navigating from auth callback to /api/tasks-list.");
          navigate("/tasks-list");
        } catch (error) {
          console.error("Error storing UID params:", error);
          navigate("/sign_up");
        }
      }
    };

    storeUIDParams();
  }, [isAuthenticated]);

  return <LoadingComponent />;
};

export default AuthCallback;
