import { useEffect, useRef, useContext } from "react";
import { Spin, notification } from "antd";
import { UnAuthenticatedApi } from "../utils/MyApi";
import { useNavigate, useLocation } from "react-router-dom";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const hasCalledAPI = useRef(false);
  const config = useContext(ConfigContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tempToken = params.get("token");
    console.log(tempToken);
    // Save the tempToken to local storage
    if (tempToken) {
      localStorage.setItem("tempToken", tempToken);
    }
    if (tempToken && !hasCalledAPI.current) {
      hasCalledAPI.current = true;
      UnAuthenticatedApi.get("/api/verify_email", {
        headers: {
          Authorization: `Bearer ${tempToken}`,
          UID: "temp",
        },
      })
        .then((response) => {
          const email = response?.data?.email;
          localStorage.setItem("tempEmail", email);
          notification.info({
            message: formatMessage(config.messages.user.email_verified),
          });
          navigate("/sign_up_redirect");
        })
        .catch((error) => {
          const errorMessage = handleError(error);
          notification.error({ message: errorMessage });
        });
    }
  }, [location.search, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" tip="Verifying your email..." />
    </div>
  );
}

export default VerifyEmail;
